"use client";

import { useIsClient } from "usehooks-ts";

import { useTranslation } from "./provider";

interface Props {
  id: string;
  children?: string;
  replace?: Record<string, string | number>;
}

export function Translation({ id, children, replace }: Props) {
  const locale = getClientSideCookie("locale") ?? "sv";
  const linkTranslations =
    getClientSideCookie("@admin/show-translations") === "true";
  const translation = useTranslation(id, children, replace);
  const isClient = useIsClient();
  if (!isClient) {
    return null;
  }
  if (linkTranslations) {
    const url = `https://cms.nextgenclassroom.se/admin/collections/localization?limit=10&page=1&search=${id}&locale=${locale}`;
    return (
      <span
        onClick={() => {
          window.open(url, "_blank");
        }}
        className="cursor-pointer border border-green-500 hover:bg-green-500"
      >
        {translation}
      </span>
    );
  }
  return <>{translation}</>;
}

export const getClientSideCookie = (name: string): string | undefined => {
  if (typeof window === "undefined") {
    return undefined;
  }
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];

  return cookieValue;
};
